/* eslint-disable  */
/**
 *
 */
const debug = require("debug")("@config:httpApi");
const agentid = "1000019"; // 华为

const WXGW_SERVER = "https://ali.gtrmt.cn:8895"; // 微信网关API
// const content_SERVER = "http://localhost:9000"; // 后台管理 HTTPS协议 部署API
const content_SERVER = "https://mgt.gtrmt.cn/"; // 后台管理 HTTPS协议 部署API

const user_Href = "https://wx-page.gtrmt.cn/"; // 华为测试

debug("=========================config");

module.exports = {
  WXGW_SERVER() {
    return WXGW_SERVER;
  },
  content_SERVER() {
    return content_SERVER;
  },
  getAgentid() {
    return agentid;
  },
  getHref() {
    return user_Href;
  },
};
