/* eslint-disable  */
/**
 * 统一Http请求接口
 * 自动带token请求
 */

const commonApi = require("./config");
// const WXGW_SERVER = "https://ali.gtrmt.cn:8894"; // 微信网关API
// const content_SERVER = "https://gtrmt.cn"; // 后台管理 部署API

const WXGW_SERVER = commonApi.WXGW_SERVER(); // 微信网关API
const content_SERVER = commonApi.content_SERVER(); // 后台管理 部署API

const post = async (url, data) => {
  // 每次取值，防止更新不及时
  const token = `Bearer ${localStorage.token}`;
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("content-type", "application/json"); // 设置 HTTP 头，数据指定为 JSON 格式
  xhr.setRequestHeader("Authorization", token);
  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status === 200) {
          var result = JSON.parse(xhr.responseText); // 必须从 responseText 读文本数据
          // console.log('1111111', result)
          resolve(result);
        } else {
          // console.log('22222222', xhr.readyState, xhr.responseText);
          reject(`HTTP API Fail: ${xhr.status},${xhr.responseText}`);
        }
      }
    };
    xhr.send(JSON.stringify(data));
  });
};
/**
 * 更新token信息
 * @param {*} newToken
 */
export const setToken = (newToken) => {
  localStorage.token = newToken;
};

/**
 * wxGwServer的请求
 */
export const wxGwPost = async (path, data) => {
  const url = `${WXGW_SERVER}${path}`;
  return await post(url, data);
};

/**
 * wxGwServer的请求
 */
export const wxConPost = async (path, data) => {
  const url = `${content_SERVER}${path}`;
  return await post(url, data);
};



/**
 * 封装过期控制代码
 * @param {*} name
 * @returns
 */

export const setLocalStorage = (key1, value) => {
  //获取时间戳
  var curTime = new Date().getTime();
  return localStorage.setItem(
    key1,
    JSON.stringify({
      data: value,
      time: curTime,
    })
  );
};

export const getLocalStorage = (key1, exp) => {
  const data = localStorage.getItem(key1);

  let dataObjDatatoJson = {};
  //转为对象
  const dataObj = data ? JSON.parse(data) : {};

  if (exp && new Date().getTime() - dataObj.time > exp) {

  } else {
    if (dataObj.data) {
      dataObjDatatoJson = dataObj.data;
    }
  }


  return dataObjDatatoJson;
};




export default {
  setToken,
  wxGwPost,
  wxConPost,
};
