import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "font-awesome/css/font-awesome.min.css";

// 按需引入
// 全局引入
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

/** 弹幕组件 */

Vue.use(ViewUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
