<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <!--微信初始化用户信息组件-->

    <h1 v-if="showWarning" style="text-align: center">
      <!-- 登录接口故障，请联系技术人员 @18310343563 ！  -->
    </h1>
  </div>
</template>

<script>
import debug from "debug";
import _ from "lodash";
import { wxConPost, setLocalStorage, getLocalStorage } from "../api/httpApi"; // Data request API
import wxLoginApi from "../api/wxLoginApi";

const _d = debug("@wxLoginCmpt");

export default {
  name: "",
  data() {
    return {
      loginStatus: false,
      showWarning: false,
      timer: null,
      exp: 60 * 1000, // 增加过期时间
    };
  },
  props: {
    login: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  methods: {
    async getUserData() {
      try {
        const userData = getLocalStorage("userData", this.exp);

        if (_.isEmpty(userData)) {
          const result = await wxConPost(
            "/services/gttt-answer-exam/dept/getUserData",
            {}
          );

          setLocalStorage("userData", result);
        }
      } catch (e) {
        _d("xxxxxxxxxxx获取用户其他数据Err", e);
      }
    },
  },
  async created() {
    _d("START LOGIN CHECK");
    // 先恢复token，如有效，不需要登录
    // await wxLoginApi.refreshToken();
    try {
      wxLoginApi.init(false, this.$router); // 实际登录
      // wxLoginApi.init(true, this.$router); // 本地调试

      wxLoginApi.onReady(() => {
        _d("wxLoginApi onReady");
        this.loginStatus = true; // 登录成功，显示页面
        localStorage.setItem("user", JSON.stringify(wxLoginApi.getUser()));

        this.getUserData();

        this.timer = setInterval(() => {
          _d("refreshToken");
          wxLoginApi.refreshToken();
        }, 3 * 60 * 1000);
      });
      setTimeout(() => {
        if (!this.loginStatus) {
          this.showWarning = true; // 接口不通，显示提示信息
        }
      }, 3000);
      _d("login first", this.$store.state.user);
    } catch (e) {
      // alert(e);
    }
  },
  destroyed() {
    if (this.timer) {
      _d("--------------------------------关闭token定时器", this.timer);
      clearInterval(this.timer);
    }
  },
};
</script>
