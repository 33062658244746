/* eslint-disable  */
/**
 * Created by mq on 17-6-17.
 * 提供前端api，获取用户信息
 */
const commonApi = require("./config");
import { wxGwPost, setToken } from "./httpApi";

const debug = require("debug")("@wxLoginApi");
const _ = require("lodash");

let $location = null;
let $router = null;
let loginSuccessed = false;
let onReadyCallbacks = [];
let userInfo = {};
/**
 * 从url中读取请求字符串
 * @param {string} name
 * @return {*}
 */
function getQueryString(name) {
  //   debug('name=======', name);
  const r = $location.href.match(
    // eslint-disable-next-line
    new RegExp(`([\?|&])${name}=([^|&]*)(&|$)`)
  );
  if (r !== null) {
    const str = decodeURI(r[2]);
    const pos = str.indexOf("#/");
    if (pos >= 0) {
      return str.substr(0, pos);
    }
    return str;
  }

  // const r = $location.href.match( // eslint-disable-next-line
  //   new RegExp(`([\?|&])${name}=([^#&]+)`));
  // if (r != null) return decodeURI(r[2]);

  return null;
}
let sceneid = null;

/**
 * 验证用户是否在企业号内
 * @param {用户id} userid
 */
function checkUserFrom(userid) {
  if (userid.indexOf("openid_") > -1) {
    return false;
  } else {
    return true;
  }
}

function toPath(userid) {
  debug("非企业号用户登录验证", userid, checkUserFrom(userid));
  console.log("非企业号用户登录验证", userid, checkUserFrom(userid));
  // 获取用户信息成功，页面跳转
  const path = getQueryString("path");
  // 分享的文章和消息可以查看详情
  if (!checkUserFrom(userid)) {
    console.log("外部人员");
    if (path.indexOf("share") > -1 || path.indexOf("msg") > -1) {
      $router.replace(path);
    } else {
      $router.replace("sharePage");
    }
  } else {
    if ($router && path) {
      console.log("内部人员", path);
      $router.replace(path);
    }
  }
}

function initUserData() {
  // 记录扫码id
  sceneid = getQueryString("sceneid");
  const usercode = getQueryString("code");
  const path = getQueryString("path");
  const url = $location.href;
  //   debug('==================================path', path, usercode, url);
  // 进行用户认证登录会话
  wxGwPost("/wx/check", {
    url,
    usercode,
    agentid: commonApi.getAgentid(), // 扫码登录应用
  })
    .then((result) => {
      debug("initUserData 用户登录成功", result);
      if (result.user) {
        debug("USER LOGIN READY:", result);
        // 成功获取用户信息
        userInfo = result.user;

        // 记录token
        if (result.token) {
          setToken(result.token);
        }
        // 当angular Ready时调用回调函数
        onReadyCallbacks.forEach((cb) => {
          debug("CALL LOGIN Ready()");
          cb();
        });
        loginSuccessed = true;
        onReadyCallbacks = [];
        debug("--------toPath 验证", userInfo.mobile);
        // 非企业号用户登录成功 && 目的路径为文章详情时，允许跳转，其他的暂时不可
        toPath(userInfo.mobile);
      } else if (result.openId) {
        // 检测用户原地址-- 转换得系统内地址
        // 获取用户源地址---转换得系统内地址--添加用户非企业内部人员信息
        // 成功获取用户信息
        userInfo["openId"] = result.openId;

        const path = getQueryString("path");
        $router.replace(`${path}`);
        // if (path.indexOf("voteDetail") > -1) {
        //   // 非企业号用户投票分享界面
        //   console.log("非企业号用户投票分享界面======", path);
        //   $router.replace(`voteDetailShare`);
        // }
      } else {
        debug("NOT USER INFO", result);
        if (result.redirect) {
          debug("11111111111111111111", result.redirect);
          $location.replace(result.redirect);
        }
      }
    })
    .catch((e) => {
      // res.status(400).json({ err: 'User Not Found' });
      const _redirectUrl = _.get(e, ["response", "data", "redirect"]);
      if (_redirectUrl) {
        // debug('22222222222222222222222222', _redirectUrl);
        $location.replace(_redirectUrl);
      } else {
        // alert(`1111111111:${path} ${$router}`);
        // _d('登录跳转', path)
        // 获取用户信息成功，页面跳转
        if ($router && path) {
          // alert(`333333333333:${path} ${path.indexOf('shareDetailTest')}`);
          // $router.replace(path);
          if (path.indexOf("shareDetail") > -1) {
            $router.replace(path);
          } else {
            // alert(`44444:${path} ${path.indexOf('shareDetailTest') > -1}`);
          }
        }
      }
      // 其他登录失败情况，如登录接口不通
    });
}

function initLocalData() {



  // 测试账号 王咸丰
  // userInfo = {
  //   userid: "15489921026426a9e105beb35bf09ad9ee0d0204a0c7d50cadbb7db",
  //   name: "王咸丰",
  //   mobile: "13522042293",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };


  // userInfo = {
  //   userid: "LiDeMin",
  //   name: "李德民",
  //   mobile: "15099969524",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };





  // userInfo = {
  //   userid: "1624503663658ad685ab1628c056cce2e7715cfa04a5b326e48f763",
  //   name: "明洲",
  //   mobile: "18774035666",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };


  // userInfo = {
  //   userid: "15471786753354777f48171928f068bfad41199658345aac4182fc4",
  //   name: "江元忠",
  //   mobile: "13822143803",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };



  // 成功获取用户信息
  // userInfo = {
  //   userid: "154710687371852536ca3deccd421c30355b89a52094c8ae0138297",
  //   name: "杜晋",
  //   mobile: "13826366167",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };





  // userInfo = {
  //   userid: "1547179331507435878786e8b31c6f5208006d48e931c2c0339ed13",
  //   name: "陈娜",
  //   mobile: "13522042293",
  //   avatar:
  //     "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  // };



  userInfo = {
    userid: "13919267170",
    name: "刘玉莹",
    mobile: "13522042293",
    avatar:
      "https://p.qlogo.cn/bizmail/4qoGYib0hJWd1QnicPVcpzkUYE2dCNreKicGaWnzTFH737TJibJc2VXVYg/0",
  };

  //  外部用户测试
  // userInfo = {
  //   userid: "openid_13919267170",
  //   name: "外部用户",
  //   mobile: "openid_13919267170",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0"
  // };
  // userInfo = {
  //   userid: "15538381466893e035c4dbbe08fb584d1406199fd2f54f195721fa3",
  //   name: "将夜",
  //   mobile: "18310343563",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
  // };
  // 长沙站段
  // userInfo = {
  //   userid: "GanLiSha",
  //   name: "甘丽莎",
  //   mobile: "15874978168",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0",
  // };
  // userInfo = {
  //   userid: "154900377726136036133c57fd247706a5066ee10354461b25780c0",
  //   name: "杨婕",
  //   mobile: "18573358520",
  //   avatar:
  //     "https://wx.qlogo.cn/mmhead/Q3auHgzwzM6grJlln8NmLeLnxkbxxsCQQczXfiaH35URqrMNULGNOVw/0"
  // };
  const path = getQueryString("path");
  // 进行用户认证登录会话
  wxGwPost("/wx/testCheck", userInfo)
    .then((result) => {
      debug("用户登录成功", result);
      setToken(result.token);
      // 当angular Ready时调用回调函数
      onReadyCallbacks.forEach((cb) => {
        debug("CALL LOGIN Ready()");
        cb();
      });
      loginSuccessed = true;
      onReadyCallbacks = [];
      // 非企业号用户登录成功 && 目的路径为文章详情时，允许跳转，其他的暂时不可
      toPath(userInfo.userid);
    })
    .catch((e) => {
      // 当angular Ready时调用回调函数
      onReadyCallbacks.forEach((cb) => {
        debug("CALL LOGIN Ready()");
        cb();
      });
      loginSuccessed = true;
      onReadyCallbacks = [];
    });
}

/**
 * 定时刷新token时间，防止过期
 */
const refreshToken = async () => {
  try {
    const ret = await wxGwPost("/code/check", {});
    // const ret = await wxGwPost("/wx/refresh", {}); // 新接口，改变gtWxPage的token时间
    if (ret.user) {
      debug("==================refreshToken", ret);
      // 保存userInfo，保存token
      userInfo = ret.user;
      setToken(ret.token);
    }
  } catch (e) {
    debug("refreshToken eeeeee", e);
  }
};
export default {
  refreshToken,
  getSceneId() {
    return sceneid; // 扫码场景id
  },
  getUser() {
    return userInfo;
  },
  init(bLocalTest, router) {
    console.log("登录=====", bLocalTest);
    $location = document.location;
    $router = router;
    // 判断是否有取过数据
    if (!_.isEmpty(this.userInfo)) {
      return;
    }
    if (bLocalTest) {
      // 本地测试，使用模拟数据
      initLocalData();
    } else {
      // 实际环境，获取用户信息
      initUserData();
    }
  },
  onReady(cb) {
    if (loginSuccessed) {
      // 已经成功登录,直接执行
      debug("EXEC LOGIN Ready()");
      setTimeout(cb);
    } else {
      // 没有登录,添加回调
      debug("PUSH LOGIN Ready()");
      onReadyCallbacks.push(cb);
    }
  },
};
