<template>
  <div id="app">
    <wx-login-cmpt></wx-login-cmpt>
    <router-view />
  </div>
</template>

<script>
import wxLoginCmpt from "./widgets/wxLoginCmpt.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  components: {
    wxLoginCmpt,
  },
  watch: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

input,
textarea {
  outline: none;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  box-shadow: inset 0px 0px 0px #2c3e50;
  transition: all 0.5s;
}

input:focus,
input:focus-visible,
textarea:focus,
textarea:focus-visible {
  outline: none;
  box-shadow: inset 0px 0px 5px #2c3e50;
}
</style>
