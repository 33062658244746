import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home.vue"),
  },

  {
    path: "/pk",
    name: "pk",
    component: () => import("../pages/pk.vue"),
  },
  {
    path: "/pkMain",
    name: "pkMain",
    component: () => import("../pages/pkMain.vue"),
  },
  {
    path: "/detailCon",
    name: "detailCon",
    component: () => import("../pages/detailCon.vue"),
  },
  {
    path: "/answer-list",
    name: "answerList",
    component: () => import("../pages/answerList.vue"),
  },

  {
    path: "/pkResult",
    name: "pkResult",
    component: () => import("../pages/pkResult.vue"),
  },

  {
    path: "/userPk",
    name: "userPk",
    component: () => import("../pages/userPk.vue"),
  },
  {
    path: "/answer-detail",
    name: "answerDetail",
    component: () => import("../pages/answerDetail.vue"),
  },
  {
    path: "/answer-detail-type",
    name: "answerDetailType",
    component: () => import("../pages/answerDetailType.vue"),
  },
  {
    path: "/ranking-list",
    name: "rankingList",
    component: () => import("../pages/rankingList.vue"),
  },
  {
    path: "/activity-main",
    name: "activityMain",
    component: () => import("../pages/activityMain.vue"),
  },
  {
    path: "/answerHisList",
    name: "answerHisList",
    component: () => import("../pages/answerHisList.vue"),
  },
  {
    path: "/log-kicker",
    name: "logKicker",
    component: () => import("../pages/logKicker.vue"),
  },
  {
    path: "/answer-detail-exercise",
    name: "answerDetailExercise",
    component: () => import("../pages/answerDetailExercise.vue"),
  },
  {
    path: "/wrong-topics",
    name: "wrongTopics",
    component: () => import("../pages/wrongTopics.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
